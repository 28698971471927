/* * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} */


.offline-strip {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
    padding: 10px 0;
    z-index: 1000;
  }
  
  .online-strip {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: green;
    color: white;
    text-align: center;
    padding: 10px 0;
    z-index: 1000;
  }
  