ul ul {
    list-style-type: "  -  ";
    margin-left: -10px;
}

.smallOnly {
    display: none;
}

.textfontsize {
    font-size: clamp(11px, 1.2vw, 2rem); 
}


@media (max-width: 1200px) {
    
}

@media (max-width: 767px) {
    .container-fluid {
        flex-direction: column !important;
    }

    .smallOnly {
        display: block;
    }

    .largeOnly {
        display: none;
    }
}